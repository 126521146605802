<template>
  <reactive-base
      v-if="user"
      app="ticket_index"
      :credentials="user.user.es_username + ':' + user.user.es_password"
      :url="elasticUrl"
  >
    <export-comments :ticketFilter="exportCommentFilter" @beginTicketExport="exportResults" @closed="closeExportComments"
                    v-if="exportCommentsModal"></export-comments>
    <export-tickets @beginTicketExport="exportResults" @closed="closeExportTickets" :exportEstimate="exportEstimate"
                    v-if="exportEstimate"></export-tickets>
    <state-provider v-if="stats">
      <div class="col-12" :includeKeys="['aggregations']" slot-scope="{ searchState }">
        <div class="row">
          <div class="col-md-6" v-if="searchState">
            <div class="widget">
              <div class="widget-header compact">
                <div class="widget-header-label">
                  <h3>
                    {{ $t('labels.ticket-status') }}
                  </h3>
                  <info-box :field="'Rubrik sifferboxar ärendestatus'" />
                </div>
              </div>
              <div class="widget-body pt-3 pb-4">
                <div class="row">
                  <div class="col text-center br-1" v-for="status in visibleTicketStatus" :key="status.id">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'statusSubFilter', $field('ticketstatus')+'.raw', $to(status,'label')) }}
                    </div>
                    <div class="label" :style="{ color: status.color, backgroundColor: status.color + '1A' }">
                      {{ $to(status,'label') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" v-if="searchState">
            <div class="widget">
              <div class="widget-header compact">
                <div class="widget-header-label">
                  <h3>
                    {{ $t('labels.priorities') }}
                  </h3>
                  <info-box :field="'Rubrik sifferboxar prioriteringar'" />
                </div>
              </div>
              <div class="widget-body pt-3 pb-4">
                <div class="row">
                  <div class="col text-center">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'prioritySubFilter', $field('priority')+'.raw', $t('labels.higher')) }}
                    </div>
                    <div class="label label-red">{{ $t('labels.higher') }}</div>
                  </div>
                  <div class="col text-center bl-1 br-1">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'prioritySubFilter', $field('priority')+'.raw', $t('labels.normal')) }}
                    </div>
                    <div class="label label-yellow">{{ $t('labels.normal') }}</div>
                  </div>
                  <div class="col text-center">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'prioritySubFilter', $field('priority')+'.raw', $t('labels.lower')) }}
                    </div>
                    <div class="label">{{ $t('labels.lower') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2" v-if="searchState">
            <div class="widget">
              <div class="widget-header compact">
                <div class="widget-header-label">
                  <h3>{{ $t('labels.increased-risk') }}</h3>
                  <info-box :field="'Rubrik sifferboxar förhöjd risk'" />
                </div>
              </div>
              <div class="widget-body pt-3 pb-4">
                <div class="row">
                  <div class="col text-center">
                    <div class="text-big text-big-xl">
                          {{ getBucketCount(searchState, 'riskPlaceholder', $field('risk')+'.raw', $i18n.locale == 'en' ? 'Risk' : 'Förhöjd risk') }}
                    </div>
                    <div class="label label-red"><i class="fas fa-exclamation-triangle"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </state-provider>
    <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
      <div class="widget">
        <div class="widget-header">
          <div class="widget-header-label">
            <h3>
              {{ $t('labels.ticket') }}
            </h3>
          </div>

          <div class="widget-header-toolbar">
            <div class="actions" v-show="enableFilter">
							<span v-for="facet in getFacetsByLevel(1)" :key="facet.id">
								<div
                    :class="{
										'filter-action-sm': facet.small,
										'filter-action': facet.type === 'list',
										'search-action': facet.type === 'search',
									}"
                    class="ml-3"
                    v-if="checkFacetPermission(facet.permission)"
                >
									<multi-dropdown-list
                      v-if="facet.type === 'list'"
                      :placeholder="facet.placeholder"
                      :componentId="facet.id"
                      :URLParams="true"
                      sortBy="asc"
                      :size="300"
                      :nestedField="facet.nestedField"
                      fuzziness="AUTO"
                      searchPlaceholder="Sök..."
                      :showSearch="true"
                      :filterLabel="facet.label"
                      :dataField="facet.field"
                      :react="{ and: getOtherFilterIds(facet.id) }"
                  />

									<data-search
                      v-if="facet.field === 'person_name'"
                      componentId="search"
                      dataField="person_name"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :placeholder="$t('labels.name')+'..'"
                      :debounce="100"
                      :autosuggest="false"
                      :filterLabel="$t('labels.search')"
                  />

									<data-search
                      v-if="facet.type === 'search' && facet.field !== 'person_name'"
                      :componentId="facet.id"
                      :dataField="facet.field"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :placeholder="facet.placeholder"
                      :debounce="100"
                      queryFormat="and"
                      :autosuggest="false"
                      :filterLabel="facet.label"
                  />
								</div>
							</span>

              <toggle-button
                  class="filter-action filter-action-xs ml-3 risk-toggle"
                  componentId="riskFilter"
                  :dataField="$field('risk')+'.raw'"
                  :URLParams="true"
                  :data="[{ label: '', value: $i18n.locale == 'en' ? 'Risk' : 'Förhöjd risk' }]"

              />
              <div class="btn btn-grey btn-bold ml-3" @click="toggleFilter" v-show="!showFilter"><i
                  class="fas fa-filter"></i></div>
              <div class="btn btn-success btn-bold ml-3" @click="toggleFilter" v-show="showFilter"><i
                  class="fas fa-filter"></i></div>


            </div>
          </div>
        </div>

        <div class="widget-filter bb-1" v-show="showFilter && enableFilter">
          <div class="row search-filters">
            <div class="col-2 mb-4" v-show="facet.visible" v-for="facet in getFacetsByLevel(2)" :key="facet.id">
              <div v-if="checkFacetPermission(facet.permission)">
                <multi-dropdown-list
                    v-if="facet.type === 'list' && facet.id === 'ageSubFilter'"
                    :placeholder="facet.placeholder"
                    :componentId="facet.id"
                    :URLParams="true"
                    sortBy="asc"
                    :nestedField="facet.nestedField"
                    fuzziness="AUTO"
                    :size="100"
                    :showSearch="true"
                    :title="facet.label"
                    :filterLabel="facet.label"
                    :dataField="facet.field"
                    :transformData="customOrder"
                    :react="{ and: getOtherFilterIds(facet.id) }"
                />

                <multi-dropdown-list
                    v-else-if="facet.type === 'list'"
                    :placeholder="facet.placeholder"
                    :componentId="facet.id"
                    :URLParams="true"
                    sortBy="asc"
                    :nestedField="facet.nestedField"
                    fuzziness="AUTO"
                    :size="100"
                    :showSearch="true"
                    :title="facet.label"
                    :filterLabel="facet.label"
                    :dataField="facet.field"
                    :react="{ and: getOtherFilterIds(facet.id) }"
                />

                <data-search
                    v-if="facet.type === 'search'"
                    :dataField="[facet.field]"
                    iconPosition="right"
                    className="data-search"
                    :showClear="true"
                    :URLParams="true"
                    fuzziness="2"
                    :filterLabel="facet.label"
                    :placeholder="facet.placeholder"
                    :componentId="facet.id"
                    :debounce="100"
                />

                <ReactiveComponent
                  v-if="facet.type === 'custom-datepicker'"
                  :componentId="facet.id"
                  :URLParams="true"
                  :filterLabel="facet.label"
                  :react="{ and: getOtherFilterIds(facet.id) }"
                >
                  <div slot-scope="{ aggregations, setQuery, value }">
                    <date-picker
                      opens="left"
                      :placeholder="facet.placeholder"
                      :label="facet.label"
                      :dataField="facet.field"
                      :aggregations="aggregations"
                      :setQuery="setQuery"
                      :selectedValue="value"
                      :show-dropdowns="facet.showDropdowns"
                      :single-date-picker="facet.singleDatePicker"
                    ></date-picker>
                  </div>
                </ReactiveComponent>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-body no-padding">
          <SelectedFilters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')"/>
        </div>
        <div class="widget-body no-padding">
          <ReactiveComponent componentId="DataControllerSensor">
            <div slot-scope="{ setQuery }">
              <custom-ticket-results :setQuery="setQuery" :query="defaultQuery"/>
            </div>
          </ReactiveComponent>

          <reactive-list
              @queryChange="getQueryChange"
              @pageChange="pageChange"
              :showResultStats="false"
              componentId="SearchResult"
              :dataField="getSortBy"
              :sortBy="getSortByOrder ? 'desc' : 'asc'"
              className="result-list-container"
              :pagination="true"
              :URLParams="true"
              :from="0"
              :size="15"
              :react="{ and: getOtherFilterIds('foo') }"
          >
            <table class="w-100 clickable-vuetable vuetable" slot="render" slot-scope="{ loading, error, data, resultStats }">
              <!-- {{resultStats}} -->

              <thead v-if="data.length !== 0">
              <tr>
                <th
                    width="6%"
                    :class="{ 'sort-up': isSortField('id', true), 'sort-down': isSortField('id', false) }"
                    @click="changeSortBy('id')"
                >
                  #
                </th>
                <th
                    width="9%"
                    :class="{ 'sort-up': isSortField('created_at', true), 'sort-down': isSortField('created_at', false) }"
                    @click="changeSortBy('created_at')"
                >
                  {{ $t('labels.created') }} <info-box :field="'Rubrikfält alla listvyer - skapat'" />
                </th>
                <th
                    width="7%"
                    :class="{ 'sort-up': isSortField('priorityid.raw', true), 'sort-down': isSortField('priorityid.raw', false) }"
                    @click="changeSortBy('priorityid.raw')"
                >
                  {{ $t('labels.priority') }}
                </th>
                <th
                    width="10%"
                    :class="{ 'sort-up': isSortField('ticketstatusid.raw', true), 'sort-down': isSortField('ticketstatusid.raw', false) }"
                    @click="changeSortBy('ticketstatusid.raw')"
                >
                  {{ $t('labels.status') }}
                </th>
                <th
                    width="9%"
                    :class="{ 'sort-up': isSortField('updated_at', true), 'sort-down': isSortField('updated_at', false) }"
                    @click="changeSortBy('updated_at')"
                >
                  {{ $t('labels.updated') }} <info-box :field="'Rubrikfält alla listvyer - ändrat'" />
                </th>
                <v-guard :permissions="['ticket-access-all-tickets']">
                  <th
                      width="11%"
                      :class="{ 'sort-up': isSortField('office.raw', true), 'sort-down': isSortField('office.raw', false) }"
                      @click="changeSortBy('office.raw')"
                  >
                    {{ $t('labels.duty') }}
                  </th>
                </v-guard>
                <v-guard :permissions="['ticket-access-only-local-office', 'ticket-access-office']">
                  <th
                      width="11%"
                      :class="{ 'sort-up': isSortField('municipality.raw', true), 'sort-down': isSortField('municipality.raw', false) }"
                      @click="changeSortBy('municipality.raw')"
                  >
                    {{ $t('labels.municipality') }}
                  </th>
                </v-guard>

                <th
                    width="16%"
                    :class="{
											'sort-up': isSortField('person_last_name.raw', true),
											'sort-down': isSortField('person_last_name.raw', false),
										}"
                    @click="changeSortBy('person_last_name.raw')"
                >
                  {{ $t('labels.name') }}
                </th>
                <th
                    width="16%"
                    :class="{ 'sort-up': isSortField('crime.raw', true), 'sort-down': isSortField('crime.raw', false) }"
                    @click="changeSortBy('crime.raw')"
                >
                  {{ $t('labels.crime') }}
                </th>
                <th
                    width="9%"
                    :class="{ 'sort-up': isSortField('language.raw', true), 'sort-down': isSortField('language.raw', false) }"
                    @click="changeSortBy('language.raw')"
                >
                  {{ $t('labels.language') }}
                </th>
                <th
                    width="5%"
                    :class="{ 'sort-up': isSortField('risk.raw', true), 'sort-down': isSortField('risk.raw', false) }"
                    @click="changeSortBy('risk.raw')"
                >
                  {{ $t('labels.risk') }}
                </th>
              </tr>
              </thead>
              <tbody v-for="item in data" :key="item.id">
                <template v-if="$to(item, 'ticketstatus') === 'Förmedlad externt'">
                  <v-guard :permissions="['ticket-view-formedla_externt']">
                    <router-link tag="tr" :to="'/tickets/ticket/' + item.id + '/overview'" 
                      :class="{ 'todo-bg': showTodoBg(item), 'interview-bg': showInterviewBg(item), 'cursor-pointer': true }">
                      <td>{{ item.id }}</td>
                      <td>{{ moment(item.created_at).format("YYYY-MM-DD") }}</td>
                      <td>{{ $to(item, 'priority') }}</td>
                      <td>{{ $to(item, 'ticketstatus') }}</td>
                      <td>{{ moment(item.updated_at).format("YYYY-MM-DD") }}</td>
                      <v-guard :permissions="['ticket-access-all-tickets']">
                        <td>{{ item.office }}</td>
                      </v-guard>
                      <v-guard :permissions="['ticket-access-only-local-office', 'ticket-access-office']">
                        <td>{{ item.municipality }}</td>
                      </v-guard>
                      <td>{{ item.person_first_name }} {{ item.person_last_name }}</td>
                      <td>{{ $to(item, 'crime') }}</td>
                      <td>{{ $to(item, 'language') }}</td>
                      <td class="text-center pl-0"
                        v-html="item.risk ? '<i class=\'fas fa-exclamation-triangle text-danger\'></i>' : ''"></td>
                    </router-link>
                  </v-guard>
                </template>
                <template v-else>
                  <router-link tag="tr" :to="'/tickets/ticket/' + item.id + '/overview'" 
                    :class="{ 'todo-bg': showTodoBg(item), 'interview-bg': showInterviewBg(item), 'cursor-pointer': true }">
                    <td>{{ item.id }}</td>
                    <td>{{ moment(item.created_at).format("YYYY-MM-DD") }}</td>
                    <td>{{ $to(item, 'priority') }}</td>
                    <td>{{ $to(item, 'ticketstatus') }}</td>
                    <td>{{ moment(item.updated_at).format("YYYY-MM-DD") }}</td>
                    <v-guard :permissions="['ticket-access-all-tickets']">
                      <td>{{ item.office }}</td>
                    </v-guard>
                    <v-guard :permissions="['ticket-access-only-local-office', 'ticket-access-office']">
                      <td>{{ item.municipality }}</td>
                    </v-guard>
                    <td>{{ item.person_first_name }} {{ item.person_last_name }}</td>
                    <td>{{ $to(item, 'crime') }}</td>
                    <td>{{ $to(item, 'language') }}</td>
                    <td class="text-center pl-0"
                      v-html="item.risk ? '<i class=\'fas fa-exclamation-triangle text-danger\'></i>' : ''"></td>
                  </router-link>
                </template>
              </tbody>
            </table>

            <template slot="renderNoResults">
              <div class="empty-placeholder pt-5">
                <i class="far fa-times-circle"></i>
                {{ $t('labels.no-results-found') }}
              </div>
            </template>
          </reactive-list>
        </div>
      </div>
    </div>
    <manual />
  </reactive-base>
</template>
<script>
import CustomTicketResults from '@/components/lists/ticket/CustomTicketResults';
import DatePicker from '@/components/DatePicker';
import BojAPI from '@/api/boj';
import ExportTickets from '@/components/ticket/ExportTickets';
import ExportComments from '@/components/ticket/ExportComments';
import Manual from '@/components/Manual';
import moment from 'moment';
import InfoBox from '@/components/InfoBox';

export default {
  props: {
    title: String,
    defaultQuery: Object,
    stats: {
      default: true,
      type: Boolean,
    },
    enableFilter: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: () => [],
      type: Array,
    },
  },
  components: {
    ExportTickets,
    ExportComments,
    CustomTicketResults,
    Manual,
    DatePicker,
    InfoBox,
  },

  data() {
    console.log('this.$field')
    console.log(this.$field)

    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      exportEstimate: false,
      exportCommentsModal: false,
      exportCommentFilter: 'all',
      ticketStatus: [],
      getSortByOrder: true,
      getSortBy: '_score',
      showFilter: false,
      latestQuery: '',
      moment: moment,
      facets: [
        {
          level: 1,
          visible: true,
          id: 'officeFilter',
          field: 'office.raw',
          label: this.$t('labels.victim-support-centre'),
          placeholder: this.$t('labels.victim-support-centre'),
          type: 'list',
          permission: 'ticket-access-all-tickets',
        },
        {
          level: 1,
          visible: true,
          id: 'localofficeFilter',
          field: 'localoffice.raw',
          label: this.$t('labels.local-office'),
          placeholder: this.$t('labels.local-office'),
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          visible: true,
          id: 'municipalityFilter',
          field: 'municipality.raw',
          label: this.$t('labels.municipality'),
          placeholder: this.$t('labels.municipality'),
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          id: 'policeCaseNumberFilter',
          visible: true,
          field: 'k_number',
          label: this.$t('labels.k-number') + '#',
          placeholder: this.$t('labels.k-number') + '#..',
          type: 'search',
          permission: 'ticket-k-number-filter',
        },
        {
          level: 1,
          id: 'nameFilter',
          visible: true,
          field: 'person_name',
          label: this.$t('labels.search'),
          placeholder: this.$t('labels.name')+'..',
          type: 'search',
          permission: null,
        },
        {
          level: 1,
          id: 'phoneFilter',
          visible: true,
          field: ['person_phone', 'person_phone_work', 'person_mobile'],
          label: this.$t('labels.phone')+'#',
          placeholder: this.$t('labels.phone')+'#..',
          type: 'search',
          permission: 'ticket-search-view-phone',
        },
        {
          level: 1,
          id: 'searchID',
          visible: true,
          field: 'id_searchable',
          label: this.$t('labels.ticket')+'#',
          placeholder: this.$t('labels.ticket')+'#..',
          type: 'search',
          permission: null,
        },
        {
          level: 2,
          visible: false,
          id: 'riskPlaceholder',
          field: this.$field('risk')+'.raw',
          label: this.$t('labels.high-risk'),
          placeholder: this.$t('labels.high-risk'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'ticketPoliceSupportOrg',
          field: this.$field('police_support_org')+'.raw',
          label: 'Stödorganisation',
          placeholder: 'Välj',
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'ticketChannelSubFilter',
          field: this.$field('ticket_channel')+'.raw',
          label: this.$t('labels.received-via'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'statusSubFilter',
          field: this.$field('ticketstatus')+'.raw',
          label: this.$t('labels.status'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'crimeSubFilter',
          field: this.$field('crime')+'.raw',
          label: this.$t('labels.crime'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'prioritySubFilter',
          field: this.$field('priority')+'.raw',
          label: this.$t('labels.priority'),
          placeholder: this.$t('labels.priority'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'updateAtDate',
          field: 'updated_at',
          label: this.$t('labels.updated-at'),
          placeholder: this.$t('labels.select'),
          type: 'custom-datepicker',
          singleDatePicker: 'range',
          showDropdowns: true,
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'createdDate',
          field: 'created_at',
          label: this.$t('labels.created-at'),
          placeholder: this.$t('labels.select'),
          type: 'custom-datepicker',
          singleDatePicker: 'range',
          showDropdowns: true,
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'categorySubFilter',
          field: this.$field('category')+'.raw',
          label: this.$t('labels.category'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'ageSubFilter',
          field: this.$field('age')+'.raw',
          label: this.$t('labels.age-group'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'genderSubFilter',
          field: this.$field('gender')+'.raw',
          label: this.$t('labels.gender'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'languageSubFilter',
          field: this.$field('language')+'.raw',
          label: this.$t('labels.language'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'crimeRelationSubFilter',
          field: this.$field('crime_relation.label')+'.raw',
          nestedField: 'crime_relation',
          label: this.$t('labels.special-circumstances'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'specialEventSubFilter',
          field: this.$field('special_event')+'.raw',
          label: this.$t('labels.special-events'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'districtSubFilter',
          field: 'district.raw',
          label: this.$t('labels.district'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'exposedAreaSubFilter',
          field: 'exposed_area.raw',
          label: this.$t('labels.exposed-areas'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: null,
        },
        {
          level: 2,
          visible: true,
          id: 'consentSubFilter',
          field: this.$field('consents.label')+'.raw',
          nestedField: 'consents',
          label: this.$t('labels.consent'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: 'ticket-consent-filter',
        },
        {
          level: 2,
          visible: true,
          id: 'courtSubFilter',
          field: 'court.raw',
          label: this.$t('labels.court'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: 'ticket-court-filter',
        },
        {
          level: 2,
          visible: true,
          id: 'addedBySubFilter',
          field: 'added_by_user.raw',
          label: this.$t('labels.created-by'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: 'ticket-added-by-filter',
        },{
          level: 2,
          visible: true,
          id: 'todostSubFilter',
          field: this.$field('has_todo')+'.raw',
          label: this.$t('labels.has-todo-list'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: 'ticket-todo-filter'
        },
        {
          level: 2,
          visible: true,
          id: 'interviewSubFilter',
          field: this.$field('is_interview_completed')+'.raw',
          label: this.$t('labels.interview-completed'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: 'ticket-interview-filter'
        },
        {
          level: 2,
          visible: true,
          id: 'userAuthorSubFilter',
          field: 'todos.user_name.raw',
          nestedField: 'todos',
          label: this.$t('labels.todo-applies'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: 'ticket-todo-filter',
        },
        {
          level: 2,
          visible: true,
          id: 'userSurveySubFilter',
          field: this.$field('survey_assessment_status')+'.raw',
          label: this.$t('labels.user-survey'),
          placeholder: this.$t('labels.select'),
          type: 'list',
          small: true,
          permission: 'ticket-survey-filter',
        },
        {
          level: 2,
          visible: true,
          id: 'userSubFilter',
          field: 'users.name.raw',
          nestedField: 'users',
          label: this.$t('labels.administrator'),
          placeholder: this.$t('labels.administrator'),
          type: 'list',
          permission: null,
        },
      ],
    };
  },
  mounted() {
    if (
        Object.keys(this.$route.query).some(function (k) {
          return k.endsWith('SubFilter');
        })
    ) {
      this.showFilter = true;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sort = urlParams.get('sort');
    const sortOrder = urlParams.get('sortOrder');

    if (sort) {
      this.getSortBy = sort;
    }
    if (sortOrder) {
      this.getSortByOrder = sortOrder === 'true';
    }
  },

  computed: {
    visibleTicketStatus: function () {
      return this.$store.getters.getTicketStatuses;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    customOrder(data) {
      let customAge = [
        'Barn 0-11',
        'Ungdom 12-17',
        'Ung vuxen 18-25',
        'Vuxen 26-64',
        'Äldre 65 +',
        'Uppgift saknas'
      ];

      let newData = data.slice().sort(function (a, b) {
        return customAge.indexOf(a.key) - customAge.indexOf(b.key);
      });

      return newData;

    },
    showTodoBg(item) {
      return ['Ja', 'Yes'].includes(item.has_todo) &&
        this.$store.getters.permissions.includes('ticket-todo-view') &&
        item.todos.filter((item) => item.is_expired == true).length > 0
    },
    showInterviewBg(item) {
      return item.consents && item.consents.find((item) => item.tag == 'interview')
    },
    checkFacetPermission(permission) {
      if (permission === null) {
        return true;
      }

      return this.$store.getters.permissions.includes(permission);
    },

    /**
     * Toggle Export modal.
     */
    closeExportTickets() {
      this.exportEstimate = null;
    },

    /**
     * Store any changes to the Elasticsearch query
     */
    getQueryChange(prevQuery, nextQuery) {
      this.latestQuery = nextQuery;
    },

    /**
     * Retrieve an estimate before actually exporting tickets.
     */
    exportResultsEstimate(template) {
      BojAPI.exportTicketsFromElasticEstimate(this.latestQuery).then((response) => {
        this.exportEstimate = response.data;
      });
    },

    exportComments(filter) {
      this.exportCommentsModal = true;
      this.exportCommentFilter = filter;
    },

    closeExportComments() {
      this.exportCommentsModal = null;
    },

    /**
     * Begin export of tickets.
     */
    exportResults(template) {
      this.exportEstimate = null;
      BojAPI.exportTicketsFromElastic(this.latestQuery).then((response) => {
      });
    },

    /**
     * Returns facets given by their level value.
     *
     * @param level
     * @returns {({visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, nestedField: string, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string})[]}
     */
    getFacetsByLevel(level) {
      let filters = this.facets.filter((a) => a.level === level);
      let hiddenFields = this.hide;
      filters = filters.filter(function (el) {
        return !hiddenFields.includes(el.field);
      });
      return filters;
    },

    /**
     * Add filter ids not present in facets.
     *
     * @param id
     * @returns {*[]}
     */
    getOtherFilterIds(id) {
      let ids = this.facets.filter((a) => a.id !== id).map((a) => a.id);
      ids.push('DataControllerSensor');
      ids.push('riskFilter');
      ids.push('search');
      ids.push('createdDate');
      ids.push('updateAtDate');

      return ids;
    },

    /**
     * Page Change Hook.
     *
     * Added to avoid scrolling of page when navigating through
     * the pages using the reactivesearch pagination.
     *
     */
    pageChange() {
    },

    /**
     * Return values from elasticsearch aggregations.
     *
     * @param searchState
     * @param componentId
     * @param aggregationId
     * @param key
     * @returns {string|number}
     */
    getBucketCount(searchState, componentId, aggregationId, key) {
      
      if (Object.keys(searchState).length && Object.prototype.hasOwnProperty.call(searchState[componentId], 'aggregations')) {
        let aggregation = searchState[componentId].aggregations[aggregationId];
        console.log('aggregation');
        console.log(aggregation);
        if (Object.prototype.hasOwnProperty.call(aggregation, 'buckets')) {
          let buckets = searchState[componentId].aggregations[aggregationId].buckets.find((item) => item.key === key);
          console.log('buckets');
          console.log(buckets);
          if (buckets) {
            return buckets.doc_count.toLocaleString('sv-SE');
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
      return 0;
    },

    /**
     * Determine if a field is currently used for sorting.
     *
     * @param field
     * @param direction
     * @returns {boolean}
     */
    isSortField(field, direction) {
      return this.getSortBy === field && this.getSortByOrder === direction;
    },

    /**
     * Toogle extra fields
     */
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    /**
     * Change sort attribute for the results.
     *
     * @param value
     */
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;

      this.updateQueryStringParam('sort', value);
      this.updateQueryStringParam('sortOrder', this.getSortByOrder);
    },

    /**
     * Updates the query string paramter.
     *
     * Currently used for adding / replacing the current sort.
     *
     * @param key
     * @param value
     */
    updateQueryStringParam(key, value) {
      const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
      const urlQueryString = document.location.search;
      const newParam = key + '=' + value;
      let params = '?' + newParam;

      // If the "search" string exists, then build params from it
      if (urlQueryString) {
        const keyRegex = new RegExp('([?&])' + key + '[^&]*');

        // If param exists already, update it - otherwise, add it to end of query string
        if (urlQueryString.match(keyRegex) !== null) {
          params = urlQueryString.replace(keyRegex, '$1' + newParam);
        } else {
          params = urlQueryString + '&' + newParam;
        }
      }
      history.pushState({}, '', baseUrl + params);
    },
  },
};
</script>